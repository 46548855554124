export const WidgetConfigAction = {
    WIDGET_INITIAL_SETUP: "WIDGET_INITIAL_SETUP",
};

export const setWidgetConfig = (query, dispatch) => {
    const fieldsToExtract = [
        "remove_header",
        "insert_caption",
        "frameHost",
        "ml_id",
        "max_files",
        "multiple",
    ];

    const isWidget = !!query.get("is_widget");
    if (isWidget) {
        sessionStorage.setItem("isWidget", true);
        sessionStorage.setItem("widgetRedirectUrl", window.location.href);
        let payload = {
            isWidget,
        };

        if (!!query.get("multiple")) {
            payload.searchConfig = {
                selectMuliple: true,
                selectMax: query.get("max_files") ? query.get("max_files") : 5,
            };
        } else {
            payload.searchConfig = {
                selectMuliple: false,
                selectMax: 1,
            };
        }

        if (query.get("max_files") > 1) {
            payload.searchConfig = {
                selectMuliple: true,
                selectMax: query.get("max_files"),
            };
        }

        payload.mediaLibraryId = query.get("ml_id");

        if (query.get("insert_caption")) {
            payload.insertCaption = query.get("insert_caption");
        }

        fieldsToExtract.forEach((field) => {
            const value = query.get(field);
            if (value !== null) {
                payload[field] = value;
            }
        });

        dispatch({
            type: WidgetConfigAction.WIDGET_INITIAL_SETUP,
            payload: payload,
        });
    }
};
