import UserService from "services/user.service";
import OrgService from "services/org.service";
import { OrgAction } from "../common/redux/actions/orgActions";
import store from "../common/redux/store";
import environment from "@src/environment";
import QueryBuilder from "./Querybuilder";
import { ORG_ROUTE_REGEX } from "@common/constants/constants";

const OrganizationGuard = async () => {
    const storeState = store.getState();
    const path = window.location.pathname;
    const isSetupOrgPath = path.includes("setup-org");
    const isChooseOrgPath = path.includes("choose-org");
    const isHome = path.includes("organization");
    const pathArray = path.split("/");
    const currentURL = window.location.pathname + window.location.search + window.location.hash;
    const queryBuilder = new QueryBuilder(window.location.origin + currentURL);
    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get("utm_source");
    const redirectUrlParam = queryParams.get("redirectUrl");
    const isolateFlow = queryParams.get("isolateFlow");
    const redirectURL = redirectUrlParam || (currentURL.match(ORG_ROUTE_REGEX) ? currentURL : null);

    if (
        storeState?.["authenticationDetails"]?.["user"]["firstName"] ===
        environment.APPLE_OAUTH_DEFAULT_NAME
    ) {
        if (utmSource && !queryParams.get("redirectToPixelbinPropertyFlow") && !redirectURL) {
            queryBuilder.append("redirectToFreePropertyFlow", "true");
        }
        return `/userinfo?${queryBuilder.toString()}`;
    }

    if (storeState["organizationDetails"]["organization"]) {
        if (isHome) {
            const orgId = parseInt(pathArray[pathArray.indexOf("organization") + 1]);
            if (orgId !== storeState["organizationDetails"]["organization"]._id) {
                return "/choose-org";
            }
            const userId = storeState["authenticationDetails"]["user"]["_id"];
            const teamResponse = await OrgService.getTeam(orgId, userId, null);
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: {
                    member: teamResponse.data.team[0],
                },
            });
        }
        return "";
    } else if (storeState["organizationDetails"]["organizations"]?.length) {
        if (isHome) {
            const orgId = parseInt(pathArray[pathArray.indexOf("organization") + 1]);
            if (
                !storeState["organizationDetails"]["organizations"].filter(
                    (org) => org._id === orgId,
                ).length
            ) {
                return "/choose-org";
            }
        }
        return "";
    } else {
        if (isChooseOrgPath) {
            try {
                const res = await UserService.getOrganizationsForUser();
                store.dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: res.data },
                });
            } catch (err) {
                console.log(err);
                return "/setup-org";
            }
            return "";
        }
        if (isSetupOrgPath) {
            try {
                const res = await UserService.getOrganizationsForUser();
                store.dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: res.data },
                });
            } catch (err) {
                return "";
            }
            return "";
        }
        try {
            let orgId = pathArray[pathArray.indexOf("organization") + 1];
            if (!orgId) {
                if (isSetupOrgPath) {
                    return "";
                }
                if (!isolateFlow || isolateFlow !== "true") {
                    return "/choose-org";
                } else {
                    return "/choose-org?isolateFlow=true";
                }
            }
            const numericOrgID = Number(orgId);
            if (isNaN(numericOrgID)) {
                const res = await UserService.getOrganizationsForUser();
                const org = res.data.find((org) => org.cloudName === orgId);
                if (!org) {
                    throw new Error("Organization not Accecible");
                } else {
                    orgId = org._id;
                }
            }
            const res = await UserService.getOrganizationDetailsById(orgId);
            const userId = storeState["authenticationDetails"]["user"]["_id"];
            const teamResponse = await OrgService.getTeam(orgId, userId, null);
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: {
                    isOrgRegistered: true,
                    organization: res.data.detail,
                    member: teamResponse.data.team[0],
                },
            });
            return "";
        } catch (err) {
            console.error(err);
            if (err.message === "Organization not Accecible") {
                return "/no-access";
            }
            if (isSetupOrgPath) {
                return "";
            }
            return "/choose-org";
        }
    }
};

export default OrganizationGuard;
