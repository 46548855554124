export const Glamarv2Actions = {
    SET_CACHE_SKU: "SET_CACHE_SKU",
    SET_PATTERN_CONFIG: "SET_PATTERN_CONFIG",
};

export const setCachedSku = (data, dispatch) => {
    dispatch({
        type: Glamarv2Actions.SET_CACHE_SKU,
        payload: data,
    });
};
export const setPatternConfig = (data, dispatch) => {
    dispatch({
        type: Glamarv2Actions.SET_PATTERN_CONFIG,
        payload: data,
    });
};
