import { Glamarv2Actions } from "../actions/glamarv2Actions";

const initialState = { cachedSku: null, patternConfig: null };

const glamarv2Reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Glamarv2Actions.SET_CACHE_SKU:
            return {
                ...state,
                cachedSku: payload,
            };
        case Glamarv2Actions.SET_PATTERN_CONFIG:
            return {
                ...state,
                patternConfig: payload,
            };
        default:
            return state;
    }
};

export default glamarv2Reducer;
